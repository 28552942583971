import React from "react"

import Layout, { Inner } from "src/components/shared/Layout"
import Typography from "src/components/shared/Typography"
import Button from "src/components/shared/Button"
import FeaturedServices from "src/components/featured-services"
import styles from "./404.styles.module.css"

import SEO from "../components/shared/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Valérie Chavot — Dien Chan Vietmassage Reiki" />
    <Inner>
      <section className={styles.Intro}>
        <Typography.h1>Erreur 404 — Page introuvable</Typography.h1>
        <Typography.p primary>
          Cette page n'existe pas ou a été supprimée.
        </Typography.p>
      </section>
    </Inner>

    <section className={styles.Services} id="services">
      <Inner>
        <Typography.h1 className={styles.ServicesTitle}>
          Consultez les prestations :
        </Typography.h1>
        <FeaturedServices />
        <Button href="/les-prestations">Voir tous les massages</Button>
      </Inner>
    </section>
  </Layout>
)

export default NotFoundPage
