import React from "react"
import SEO from "src/components/shared/seo"
import Layout, { Inner } from "src/components/shared/Layout"
import ContactForm from "src/components/shared/ContactForm"
import Typography from "src/components/shared/Typography"
import styles from "./valerie-chavot.styles.module.css"
import ArrowSeparator from "src/components/shared/ArrowSeparator"

import Section from "src/components/shared/Section"
import ValerieChavot from "src/images/valerie-chavot.jpg"
import ValerieChavotMassage from "src/images/valerie-chavot-massage.jpg"
import { Link } from "gatsby"

const AboutPage = () => (
  <Layout bannerImage="lotus">
    <SEO title="Valérie Chavot — Dien Chan Chi Nei Tsang Reiki" />
    <Inner>
      <section className={styles.Intro}>
        <Typography.h1>Qui suis-je ?</Typography.h1>
        <Typography.p primary>
          Praticienne en Dien Chan, Chi Nei Tsang et massages bien-être.
          Contactez-moi via le <Link to="contact">formulaire de contact</Link>,
          par téléphone au 06.72.64.31.50 ou par mail à contact@absolusens.com.
        </Typography.p>
      </section>
    </Inner>

    <ArrowSeparator to="about" />

    <Section id="about" image={ValerieChavot}>
      <Typography.h1>Valérie Chavot</Typography.h1>
      <Typography.p primary>
        Praticienne en Dien Chan, Chi Nei Tsang et massages bien-être. Aide
        soignante et auxiliaire de puériculture de profession, j’ai eu
        l’occasion de travailler en chirurgie, auprès de personnes âgées,
        d’adultes handicapés et d’enfants. Ces diverses expériences m’ont
        données envie d’apporter de la détente et du soin par le massage
        bien-être et le Dien Chan.
      </Typography.p>
    </Section>

    <Section inverted id="certifications" image={ValerieChavotMassage}>
      <Typography.h1>Certifications</Typography.h1>
      <Typography.p primary>
        <ul>
          <li>IFJS - École Européenne du Toucher-Massage</li>
          <li>Institut EDUCASENS</li>
          <li>Académie internationale de Dien Chan</li>
        </ul>
      </Typography.p>
    </Section>

    <div className={styles.ContactForm}>
      <Inner small id="contact">
        <ContactForm />
      </Inner>
    </div>
  </Layout>
)

export default AboutPage
