import React from "react"
import SEO from "src/components/shared/seo"
import Layout, { Inner } from "src/components/shared/Layout"
import { graphql } from "gatsby"
import ContactForm from "src/components/shared/ContactForm"
import Button from "src/components/shared/Button"
import FeaturedServices from "src/components/featured-services"
import Typography from "src/components/shared/Typography"
import styles from "./prestation.styles.module.css"

export const ServicePageTemplate = ({
  name,
  featuredimage,
  prices,
  note,
  html,
  preview,
}) => {
  return (
    <Layout bannerImage="lotus">
      <SEO title={name} />
      <Inner>
        <section className={styles.Intro}>
          <Typography.h1>{name}</Typography.h1>

          {!!preview ? (
            <Typography.p primary>{html}</Typography.p>
          ) : (
            <div
              className={styles.Paragraph}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}

          <div className={styles.Prices}>
            {prices &&
              prices.map(({ type, price }) => (
                <Typography.p primary className={styles.Price}>
                  <span>{price}</span>
                  <span>{type}</span>
                </Typography.p>
              ))}
          </div>
          <Typography.p>{note}</Typography.p>
          <Button href="contact">Prendre rendez-vous</Button>
        </section>
      </Inner>

      {!preview && (
        <>
          <section className={styles.Services} id="services">
            <Inner>
              <Typography.h1 className={styles.ServicesTitle}>
                Les autres prestations
              </Typography.h1>
              <Typography.p>
                Toutes ces prestations ont pour but d’apporter détente et
                bien-être et ne peuvent en aucun cas se substituer aux
                prescriptions médicales.
              </Typography.p>
              <FeaturedServices />
              <Button href="/les-prestations">Voir tous les massages</Button>
            </Inner>
          </section>

          <div className={styles.ContactForm}>
            <Inner small id="contact">
              <ContactForm />
            </Inner>
          </div>
        </>
      )}
    </Layout>
  )
}

const ServicePage = ({ data }) => {
  console.log(data)
  const {
    markdownRemark: {
      frontmatter: { name, featuredimage, prices, note },
      html,
    },
  } = data

  return (
    <ServicePageTemplate
      name={name}
      featuredimage={featuredimage}
      prices={prices}
      note={note}
      html={html}
    />
  )
}

export default ServicePage

export const presentationQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        name
        featuredimage
        note
        prices {
          type
          price
        }
      }
    }
  }
`
