import React from "react"
import styles from "./styles.module.css"

export const Input = props => (
  <input type="text" className={styles.Input} {...props} />
)

export const TextArea = props => (
  <textarea rows="6" className={styles.Input} {...props} />
)
