import React from "react"
import cx from "classnames"
import styles from "./styles.module.css"

const Typography = {}

const Header1 = props => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h1 {...props} className={cx(styles.Header1, props.className)} />
)

const Header2 = props => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h2 {...props} className={cx(styles.Header2, props.className)} />
)

const Header3 = props => (
  // eslint-disable-next-line jsx-a11y/heading-has-content
  <h3 {...props} className={cx(styles.Header3, props.className)} />
)

const Paragraph = ({ primary, light, ...props }) => (
  <p
    {...props}
    className={cx(
      styles.Paragraph,
      { [styles.Primary]: primary },
      { [styles.Light]: light },
      props.className
    )}
  />
)

Typography.h1 = Header1
Typography.h2 = Header2
Typography.h3 = Header3
Typography.p = Paragraph

export default Typography
