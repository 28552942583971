import PropTypes from "prop-types"
import React from "react"
import Logo from "src/components/shared/Logo"
import Link from "src/components/shared/Link"
import Button from "src/components/shared/Button"
import styles from "./styles.module.css"

const Header = ({ siteTitle }) => (
  <header className={styles.Header}>
    <Link to="/">
      <Logo />
    </Link>
    <ul className={styles.List}>
      {/* <li className={styles.ListItem}>
        <Link to="/">Accueil</Link>
      </li> */}
      <li className={styles.ListItem}>
        <Link to="/valerie-chavot">Qui suis-je ?</Link>
      </li>
      <li className={styles.ListItem}>
        <Link to="/les-prestations">Les prestations</Link>
      </li>
      <li className={styles.ListItem}>
        <Button white href="/contact">
          Contactez-moi
        </Button>
      </li>
    </ul>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
