import React from "react"
import svgLogo from "src/images/logo-absolu-sens.svg"
import pngLogo from "src/images/logo-absolu-sens.png"
import Illustration from "src/components/shared/Illustration"
import { detect } from "detect-browser"
import styles from "./styles.module.css"

const Logo = () => {
  const browser = detect()
  const isIE = browser.name === "ie"
  return (
    <Illustration
      className={styles.Logo}
      src={isIE ? pngLogo : svgLogo}
      alt="AbsoluSens"
    />
  )
}

export default Logo
