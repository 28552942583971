import React from "react"

const Shape = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
    <defs>
      <clipPath id="shape">
        <path
          d="M-940.685-79s-263.322,187.78,166.631,460.707c8.834,4.017,125.1,78.636,177.237,190.918.44,4.41,124.145,225.606,208,263.677,90.055,40.885,215.709,42.9,366.99-98.848,0,0,495-450.457,210.447-794.557Z"
          transform="translate(1020.496 78.998)"
          fill="rgba(255,255,255,0.42)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default Shape
