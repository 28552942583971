import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import styles from "./styles.module.css"

const Illustration = ({ src, alt, className, withShadow }) => (
  <img
    src={src}
    alt={alt}
    className={cx({ [styles.WithShadow]: withShadow }, className)}
  />
)

Illustration.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
}

export default Illustration
