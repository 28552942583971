import React from "react"
import SEO from "src/components/shared/seo"
import { graphql, StaticQuery } from "gatsby"
import Layout, { Inner } from "src/components/shared/Layout"
import ContactForm from "src/components/shared/ContactForm"
import Typography from "src/components/shared/Typography"
import ServiceCard, {
  ServiceCardContainer,
} from "src/components/shared/ServiceCard"
import styles from "./index.styles.module.css"
import Button from "src/components/shared/Button"
import ArrowSeparator from "src/components/shared/ArrowSeparator"

const ServicesPage = ({ services }) => (
  <Layout bannerImage="lotus">
    <SEO title="Les prestations — Dien Chan Vietmassage Reiki" />
    <Inner>
      <section className={styles.Intro}>
        <Typography.h1>Les prestations</Typography.h1>
        <Typography.p primary>
          Mal de dos ? Stress ? Fatigue ? AbsoluSens vous propose plusieurs
          méthodes de massages; du Dien Chan en passant par la réflexologie
          plantaire et s’adapte à tous.
        </Typography.p>
        <Button href="/contact">Prendre rendez-vous</Button>
      </section>
    </Inner>

    <ArrowSeparator to="services" />

    <Inner id="services">
      <ServiceCardContainer>
        {services.map(({ name, description, prices, duration, link, note }) => (
          <div className={styles.Service}>
            <ServiceCard
              name={name}
              description={description}
              price={prices && prices[0].price}
              duration={prices && prices[0].type}
              link={link}
            />
            {note && <Typography.p>{note}</Typography.p>}
          </div>
        ))}
      </ServiceCardContainer>
    </Inner>

    <div className={styles.ContactForm}>
      <Inner small id="contact">
        <ContactForm />
      </Inner>
    </div>
  </Layout>
)

export default () => (
  <StaticQuery
    query={graphql`
      query ServicesQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "prestation" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                name
                prices {
                  price
                  type
                }
                description
                note
              }
            }
          }
        }
      }
    `}
    render={data =>
      console.log(data) || (
        <ServicesPage
          services={data.allMarkdownRemark.edges.map(edge => ({
            ...edge.node.frontmatter,
            link: edge.node.fields.slug,
          }))}
        />
      )
    }
  />
)
