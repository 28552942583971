import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { Inner } from "src/components/shared/Layout"
import Illustration from "src/components/shared/Illustration"

import Lotus from "src/images/lotus.jpg"
import styles from "./styles.module.css"

const Section = ({ inverted, image, children, ...props }) => (
  <Inner {...props}>
    <section className={cx(styles.Section, { [styles.Inverted]: inverted })}>
      <Illustration
        withShadow
        src={image}
        className={styles.SectionIllustration}
      />
      <div className={styles.SectionText}>{children}</div>
    </section>
  </Inner>
)

Section.propTypes = {
  inverted: PropTypes.bool,
  bannerImage: PropTypes.node,
  children: PropTypes.node.isRequired,
}

Section.defaultProps = {
  bannerImage: Lotus,
}

export default Section
