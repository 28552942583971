import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Typography from "src/components/shared/Typography"
import Illustration from "src/components/shared/Illustration"
import Button from "src/components/shared/Button"
import { Inner } from "src/components/shared/Layout"
import styles from "./styles.module.css"
import Lotus from "src/images/lotus.jpg"

const aboutQuery = graphql`
  query AboutTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "home-about" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`

export const AboutTemplate = ({ preview, title, html }) => (
  <section>
    <Inner id="about">
      <section className={styles.About}>
        <Illustration
          withShadow
          src={Lotus}
          className={styles.AboutIllustration}
        />
        <div className={styles.AboutText}>
          <Typography.h1>{title}</Typography.h1>
          {!!preview ? (
            <Typography.p primary>{html}</Typography.p>
          ) : (
            <div
              className={styles.Paragraph}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          )}
          <div className={styles.Addresses}>
            <Typography.p primary className={styles.Address}>
              2 ruelle des Véens<br />
              89000 <b>Auxerre</b>
            </Typography.p>
            <Typography.p primary className={styles.Address}>
              1 Vieille Rue d'Auxerre <br />
              89660 <b>Mailly-le-Château</b>
            </Typography.p>
          </div>
          <Button href="/valerie-chavot">En savoir plus</Button>
        </div>
      </section>
    </Inner>
  </section>
)

const About = () => {
  const data = useStaticQuery(aboutQuery)
  const {
    markdownRemark: {
      frontmatter: { title },
      html,
    },
  } = data

  return <AboutTemplate title={title} html={html} />
}

export default About
