import PropTypes from "prop-types"
import React from "react"
import styles from "./styles.module.css"
import Typography from "src/components/shared/Typography"
import Button from "src/components/shared/Button"

export const ServiceCardContainer = props => (
  <div {...props} className={styles.ServiceCardsContainer} />
)

const ServiceCard = ({ name, description, price, duration, link }) => (
  <div className={styles.Card}>
    <div>
      <Typography.h2>{name}</Typography.h2>
      <Typography.p light>{description}</Typography.p>
    </div>
    <div>
      <Typography.p className={styles.Price}>{price}</Typography.p>
      <Typography.p className={styles.Duration}>{duration}</Typography.p>
      <Button bordered href={link}>
        Voir plus
      </Button>
    </div>
  </div>
)

ServiceCard.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.string,
  duration: PropTypes.string,
  link: PropTypes.string,
}

export default ServiceCard
