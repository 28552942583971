import React from "react"
import Logo from "src/components/shared/Logo"
import Illustration from "src/components/shared/Illustration"
import Link from "src/components/shared/Link"
import Typography from "src/components/shared/Typography"
import PhoneBook from "src/images/icon-phone-book.svg"
import Email from "src/images/icon-email.svg"
import { Inner } from "src/components/shared/Layout"
import styles from "./styles.module.css"

const Footer = () => (
  <footer className={styles.Footer}>
    <Inner>
      <div className={styles.FooterContent}>
        <Link to="/">
          <Logo />
        </Link>
        <div>
          <Typography.h3 className={styles.ListTitle}>
            Absolu sens
          </Typography.h3>
          <ul>
            <li className={styles.ListItem}>
              <Link to="/">Accueil</Link>
            </li>
            <li className={styles.ListItem}>
              <Link to="/valerie-chavot">Qui suis-je ?</Link>
            </li>
            <li className={styles.ListItem}>
              <Link to="/les-prestations">Les prestations</Link>
            </li>
            <li className={styles.ListItem}>
              <Link to="/contact">Contactez-moi</Link>
            </li>
          </ul>
        </div>
        <div>
          <Typography.h3 className={styles.ListTitle}>
            Informations
          </Typography.h3>
          <ul>
            <li className={styles.ListItem}>
              <Illustration className={styles.Icon} src={PhoneBook} />
              {"   "}
              <Link href="tel:0672643150">06.72.64.31.50</Link>
            </li>
            <li className={styles.ListItem}>
              <Illustration className={styles.Icon} src={Email} />
              {"   "}
              <Link href="mailto:contact@absolusens.com">
                contact@absolusens.com
              </Link>
            </li>
            <li className={styles.ListItem}>
              <div className={styles.Addresses}>
                <Typography.p className={styles.Address}>
                  <Link target="_blank" href="https://www.google.fr/maps/place/2+Pl.+des+V%C3%A9ens,+89000+Auxerre">
                    2 ruelle des Véens<br />
                    89000 <b>Auxerre</b>
                  </Link>
                </Typography.p>
                <Typography.p primary className={styles.Address}>
                  <Link target="_blank" href="https://www.google.com/maps/place/1+Vieille+Rue+d'Auxerre,+89660+Mailly-le-Ch%C3%A2teau">
                    1 Vieille Rue d'Auxerre <br />
                    89660 <b>Mailly-le-Château</b>
                  </Link>
                </Typography.p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <Typography.p light className={styles.Copyright}>
        Copyright @absolusens - 2023
      </Typography.p>
    </Inner>
  </footer>
)

export default Footer
