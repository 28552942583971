// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-les-prestations-index-js": () => import("./../../../src/pages/les-prestations/index.js" /* webpackChunkName: "component---src-pages-les-prestations-index-js" */),
  "component---src-pages-merci-js": () => import("./../../../src/pages/merci.js" /* webpackChunkName: "component---src-pages-merci-js" */),
  "component---src-pages-valerie-chavot-js": () => import("./../../../src/pages/valerie-chavot.js" /* webpackChunkName: "component---src-pages-valerie-chavot-js" */),
  "component---src-templates-prestation-js": () => import("./../../../src/templates/prestation.js" /* webpackChunkName: "component---src-templates-prestation-js" */)
}

