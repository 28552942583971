import React from "react"
import { graphql, StaticQuery } from "gatsby"
import ServiceCard, {
  ServiceCardContainer,
} from "src/components/shared/ServiceCard"

const FeaturedServices = ({ services }) => (
  <ServiceCardContainer>
    {services.map(({ name, description, prices, duration, link, note }) => (
      <ServiceCard
        name={name}
        description={description}
        price={prices && prices[0].price}
        duration={prices && prices[0].type}
        link={link}
      />
    ))}
  </ServiceCardContainer>
)

export default () => (
  <StaticQuery
    query={graphql`
      query FeaturedServicesQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              templateKey: { eq: "prestation" }
              featured: { eq: true }
            }
          }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                name
                prices {
                  price
                  type
                }
                description
                note
              }
            }
          }
        }
      }
    `}
    render={data =>
      console.log(data) || (
        <FeaturedServices
          services={data.allMarkdownRemark.edges.map(edge => ({
            ...edge.node.frontmatter,
            link: edge.node.fields.slug,
          }))}
        />
      )
    }
  />
)
