import { Link as GatsbyLink } from "gatsby"
import React from "react"
import styles from "./styles.module.css"
import { Link as ScrollLink } from "react-scroll"

const style = {
  color: "inherit",
  textDecoration: "inherit",
}

const Link = ({ smooth, href, ...props }) => (
  <span className={styles.Link}>
    {smooth ? (
      <ScrollLink {...props} smooth />
    ) : href ? (
      <a {...props} href={href} style={style} />
    ) : (
      <GatsbyLink style={style} {...props} />
    )}
  </span>
)

export default Link
