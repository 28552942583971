import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import cx from "classnames"

import Salon from "src/images/edge/salon.png"
import LotusLarge from "src/images/edge/lotus-large.png"

import Header from "src/components/shared/Header"
import Footer from "src/components/shared/Footer"
import TopIllustration from "src/components/shared/TopIllustration"
import styles from "./styles.module.css"

export const Inner = props => (
  <div
    className={cx(styles.Inner, { [styles.Small]: props.small })}
    {...props}
  />
)

const Layout = ({ bannerImage, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div className={styles.Layout}>
        <Inner>
          <Header siteTitle={data.site.siteMetadata.title} />

          <TopIllustration
            src={bannerImage === "salon" ? Salon : LotusLarge}
            alt={data.site.siteMetadata.title}
            className={styles.TopIllustration}
          />
        </Inner>
        <div>
          <main>{children}</main>
        </div>

        <Footer />
      </div>
    )}
  />
)

Layout.propTypes = {
  bannerImage: PropTypes.node,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  bannerImage: "salon",
}

export default Layout
