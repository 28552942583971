import React from "react"
import Layout, { Inner } from "src/components/shared/Layout"
import Typography from "src/components/shared/Typography"
import SEO from "src/components/shared/seo"
import styles from "./index.styles.module.css"
import Button from "src/components/shared/Button"
import Illustration from "src/components/shared/Illustration"
import ContactForm from "src/components/shared/ContactForm"
import ArrowSeparator from "src/components/shared/ArrowSeparator"
import Massage from "src/images/massage.png"

import About from "src/components/homepage/About"
import Presentation from "src/components/homepage/Presentation"
import FeaturedServices from "src/components/featured-services"

const IndexPage = () => (
  <Layout>
    <SEO title="Absolu Sens — Dien Chan Vietmassage Reiki" />
    <Presentation />

    <ArrowSeparator to="about" />

    <About />

    <section className={styles.Services} id="services">
      <Inner>
        <Typography.h1 className={styles.ServicesTitle}>
          Les prestations
        </Typography.h1>
        <FeaturedServices />
        <Button href="/les-prestations">Voir tous les massages</Button>
      </Inner>
    </section>

    <section className={styles.Contact} id="contact">
      <Inner>
        <div className={styles.ContactContainer}>
          <div className={styles.ContactText}>
            <ContactForm />
          </div>
          <Illustration
            withShadow
            src={Massage}
            className={styles.ContactIllustration}
          />
        </div>
      </Inner>
    </section>
  </Layout>
)

export default IndexPage
