import React from "react"
import styles from "./styles.module.css"
import Illustration from "src/components/shared/Illustration"
import { Link as ScrollLink } from "react-scroll"
import Arrow from "./icon-arrow.svg"

const ArrowSeparator = ({ to }) => (
  <ScrollLink className={styles.ArrowContainer} to={to} smooth={true}>
    <Illustration src={Arrow} />
  </ScrollLink>
)

export default ArrowSeparator
