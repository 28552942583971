import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import Illustration from "src/components/shared/Illustration"
import Shape from "./Shape"
import GradientLayer from "./gradient-layer.svg"
import styles from "./styles.module.css"

const TopIllustration = ({ src, alt, className }) => (
  <div className={cx(styles.Container, className)}>
    <Shape />
    <Illustration src={src} alt={alt} className={styles.TopIllustration} />
    <Illustration src={GradientLayer} className={styles.GradientLayer} />
  </div>
)

TopIllustration.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
}

export default TopIllustration
