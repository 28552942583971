import React from "react"
import SEO from "src/components/shared/seo"
import Layout, { Inner } from "src/components/shared/Layout"
import ContactForm from "src/components/shared/ContactForm"
import Typography from "src/components/shared/Typography"
import styles from "./contact.styles.module.css"

const ContactPage = () => (
  <Layout bannerImage="lotus">
    <SEO title="Contact" />
    <Inner>
      <section className={styles.Intro}>
        <ContactForm />
        <Typography.p>
          Vous pouvez également prendre rendez-vous par téléphone au
          <b> 06.72.64.31.50</b> ou par mail à <b>contact@absolusens.com</b>.
        </Typography.p>
      </section>
    </Inner>
  </Layout>
)

export default ContactPage
