import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Typography from "src/components/shared/Typography"
import Button from "src/components/shared/Button"
import { Inner } from "src/components/shared/Layout"
import styles from "./styles.module.css"

const presentationQuery = graphql`
  query PresentationTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "home-presentation" } }) {
      html
      frontmatter {
        title
      }
    }
  }
`

export const PresentationTemplate = ({ preview, title, html }) => (
  <section>
    <Inner>
      <div className={styles.Intro}>
        <Typography.h1 className={styles.Header}>{title}</Typography.h1>
        {!!preview ? (
          <Typography.p primary>{html}</Typography.p>
        ) : (
          <div
            className={styles.Paragraph}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}
        <Button href="/les-prestations">Voir les massages</Button>
      </div>
    </Inner>
  </section>
)

const Presentation = () => {
  const data = useStaticQuery(presentationQuery)
  const {
    markdownRemark: {
      frontmatter: { title },
      html,
    },
  } = data

  return <PresentationTemplate title={title} html={html} />
}

export default Presentation
