import PropTypes from "prop-types"
import React from "react"
import cx from "classnames"
import Link from "src/components/shared/Link"
import styles from "./styles.module.css"

const Button = ({ href, children, white, bordered, type, className }) =>
  type === "submit" ? (
    <button
      className={cx(
        styles.Button,
        { [styles.White]: white, [styles.Bordered]: bordered },
        className
      )}
    >
      <div>{children}</div>
    </button>
  ) : (
    <div
      className={cx(
        styles.Button,
        { [styles.White]: white, [styles.Bordered]: bordered },
        className
      )}
    >
      <Link className={styles.Link} to={href}>
        {children}
      </Link>
    </div>
  )

Button.propTypes = {
  href: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  white: PropTypes.bool,
  bordered: PropTypes.bool,
  className: PropTypes.string,
}

export default Button
